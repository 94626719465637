import React from 'react';

import { Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

import { phoneFormat } from '@common/react/components/Forms/FormikPhoneControl/FormikPhoneControl';
import { days, timeFormat } from '@common/react/utils/timeUtils';
import ImageLazy from '@common/react/components/UI/ImageLazy/ImageLazy';

import SimpleMap from '@app/components/UI/Map/Map';
import { Location } from '@app/objects/Location';

interface Props {
	location: Location;
}

const Contacts: React.FC<Props> = ({ location }) => {
	const link = React.useMemo(() => {
		let res = `https://maps.google.com/maps/search/?api=1&query=${location.addressEn}`;
		if (typeof window !== 'undefined') {
			if (/iPhone|iPad|iPod/i.test(navigator.userAgent)) {
				res = `maps://maps.google.com/maps/search/?api=1&query=${location.addressEn}`;
			}
		}
		return res;
	}, [location]);
	const arr = React.useMemo(() =>
		[location.addressEn, location.city, location.state, location.zip].filter((str) => str), []);
	const address = arr.filter((e) => e).join(', ');

	const onClick = (e) => {
		const el = e?.target;
		if (el?.closest('.gm-control-active') || el?.classList.contains('gm-control-active')
			|| el.tagName?.toLowerCase() === 'a') {
			e.preventDefault();
		}
	};

	const workingHours = React.useMemo(() => {
		const whs = location.workingHours.slice()
			.sort((a, b) => {
				if (a.dayOfWeek === 0) return 1;
				if (b.dayOfWeek === 0) return -1;
				return a.dayOfWeek - b.dayOfWeek;
			});
		const obj: any = {};
		whs.forEach((wh) => {
			const key = `${wh.startTime}-${wh.endTime}`;
			if (!obj[key]) {
				obj[key] = [{ ...wh }];
			} else if (!obj[key].some((item) => item.dayOfWeek === wh.dayOfWeek)) {
				obj[key] = obj[key].concat(wh);
			}
		});
		return Object.values(obj)
			.map((arr: any, i) => {
				let title = days[arr[0].dayOfWeek];
				for (let i = 1; i < arr.length; i++) {
					const isEnd = arr[i].dayOfWeek === 6 && arr[i + 1]?.dayOfWeek === 0;
					if (arr[i].dayOfWeek - 1 !== arr[i - 1]?.dayOfWeek && !isEnd && !(arr[i].dayOfWeek === 0 && arr[i - 1]?.dayOfWeek === 6)) {
						title = `${title}, ${days[arr[i].dayOfWeek]}`;
					} else if (i + 1 === arr.length
						|| (arr[i].dayOfWeek + 1 !== arr[i + 1]?.dayOfWeek && !isEnd)) {
						title = `${title} - ${days[arr[i].dayOfWeek]}`;
					}
				}
				return {
					id: i,
					title,
					startTime: arr[0].startTime,
					endTime: arr[0].endTime,
				};
			});
	}, [location.workingHours]);

	return (
		<div className="map-container">
			<div className="contacts__location-wrapper">
				{location.files?.length ? <div className="contacts__location-images">
					<Swiper
						pagination={{
							clickable: true,
						}}
						modules={[Pagination]}
						className="mySwiper"
						autoHeight
						observer
						observeParents
						observeSlideChildren
					>
						{location.files.map((item) => <SwiperSlide key={item.id}>
							<ImageLazy
								alt={`clinic ${item.id}`}
								width="300px"
								height="300px"
								className="slider-image"
								src={`/remote/${item.file.src}`}
							/>
						</SwiperSlide>)}
					</Swiper>
				</div> : null}
				<div className="clearfix contacts__location">
					<div className="location-name clearfix">
						<h3>
							{location.companyName}
							{' - '}
							{location.nameEn}
						</h3>
						<div className="clearfix">
							<h4 className="pull-left">
								<a
									onClick={onClick}
									title={`Open ${location.companyName} address at google maps`}
									target="_blank"
									href={link}
									rel="noreferrer"
								>
									<label>Address:</label>
									<address>{address}</address>
								</a>
							</h4>
							{location.phone && <h4 className="pull-left">
								<a href={`tel:${location.phone}`} title={`Call ${location.companyName} Clinic`}>
									<label>Phone:</label>
									{' '}
									{phoneFormat(location.phone)}
								</a>
							</h4>}
							{location.portalEmail && <h4 className="pull-left">
								<a href={`mailto:${location.portalEmail}`} title={`Send Email to ${location.companyName} Clinic`}>
									<label>Email:</label>
									{' '}
									{location.portalEmail}
								</a>
							</h4>}
						</div>
					</div>
					{location.workingHours && <div className="mr10 working-hours">
						<h4><label>Hours: </label></h4>
						<div>
							<ul className="working-hour-list">
								{
									workingHours
										.map((wh) => <li className="working-hour" key={wh.id}>
											<div className="text-center">
												{wh.title}
											</div>
											<div className="text-center">
												{timeFormat(wh.startTime)}
												{' - '}
												{timeFormat(wh.endTime)}
											</div>
										</li>)
								}
							</ul>
						</div>
					</div>}
				</div>
			</div>
			<a
				onClick={onClick}
				target="_blank"
				href={link}
				rel="noreferrer"
			>
				<SimpleMap address={location.addressEn} googleApiKey={location?.company?.googleApiKey || ''} />
			</a>
		</div>
	);
};

export default Contacts;
